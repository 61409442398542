<script>
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useAddresses } from '/~/composables/addresses'
import {
  useStatementCheckout,
  useCheckoutReactive,
} from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { sortPaymentMethodsByType } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import PaymentSuccessfulPayWith from '/~/templates/bill-payments/views/statements/checkout/successful/payment-successful-pay-with.vue'
import TaxInvoice from '/~/views/activity/details/components/tax-invoice.vue'

export default defineComponent({
  name: 'statement-details-item',
  components: {
    PayeeIcon,
    BaseBack,
    BaseButton,
    BaseIcon,
    PaymentSuccessfulPayWith,
    PointsPanel,
    TaxInvoice,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    parentPage: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const router = useRouter()
    const {
      fetchStatement,
      statement,
      fetching,
      loadingFees,
      programFees,
      total,
      subTotalWithProgramFees,
    } = useStatementCheckout()

    const { currentFlowType, payment } = useCheckoutReactive()
    const { defaultAddress } = useAddresses()
    const { user } = useUser()
    const { isPaymentOrderPoints, calculatePointsEarnedForPayment } =
      usePoints()
    const { taxationLabel } = useProvider()

    const paymentMethods = computed(() =>
      statement.value
        ? sortPaymentMethodsByType(statement.value.paymentMethods)
        : []
    )

    const redirect = () => {
      if (statement.value?.isBlocked) {
        router.replace({ name: 'statements' })
      }
    }

    if (statement.value?.id !== props.id) {
      fetchStatement(props.id).then(redirect)
    }

    function pay() {
      currentFlowType.value = FlowType.statement
      payment.value.initPayment({
        statement: statement.value,
      })

      router.push({
        name: 'statement-checkout',
        id: statement.value.id,
      })
    }

    return {
      statement,
      total,
      subTotalWithProgramFees,
      loadingFees,
      programFees,
      loading: fetching,
      calculatePointsEarnedForPayment,
      isPaymentOrderPoints,
      user,
      formatDollar,
      defaultAddress,
      paymentMethods,
      ui,
      taxationLabel,
      pay,
    }
  },
  computed: {
    pointsEarned() {
      return this.calculatePointsEarnedForPayment(this.statement?.subtotal)
    },
    title() {
      return this.statement?.payeeName ?? ''
    },
    subtitle() {
      return this.statement?.statementDateMonthName + ' Statement'
    },
    date() {
      return formatDate('daymonthyear', this.statement?.processingDueDate)
    },
    paidAt() {
      return formatDate('daymonthyearlongtime', this.statement?.paidAt)
    },
    printContent() {
      return {
        ...(this.statement?.raw ?? {}),
        payees: [
          {
            ...this.statement?.raw?.payee,
            subtotal: this.statement?.subtotal,
          },
        ],
        address: this.statement?.address || this.defaultAddress,
      }
    },
    canPay() {
      return this.statement?.active
    },
    showPrintButton() {
      return this.statement?.isCompleted
    },
    statusColor() {
      if (this.statement.isCompleted) {
        return 'text-fg-success'
      } else if (this.statement.isPending) {
        return 'text-fg-warning'
      }
      return 'text-fg-error'
    },
  },
  methods: {
    print() {
      window.print()
    },
  },
})
</script>

<template>
  <div class="flex h-full w-full">
    <div
      class="flex h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mr-6 sm:pt-[30px]"
    >
      <div
        class="relative mx-auto flex h-full w-full max-w-3xl flex-col bg-white sm:rounded-t-3xl sm:pt-[35px]"
      >
        <div
          class="mt-5 flex items-center justify-between px-5 pb-5 sm:mt-0 sm:px-10"
        >
          <base-back
            v-if="!ui.mobile"
            class="block self-start"
            :to="{
              name: 'statements',
              query: { page: `${parentPage}` },
            }"
          />
          <base-button
            v-if="showPrintButton"
            :disabled="loading"
            look="link"
            class="text-sm"
            @click="print"
          >
            <base-icon svg="plain/printer" size="md" class="mr-[5px]" />
            <span class="text-sm">Print Tax Invoice</span>
          </base-button>
        </div>

        <payee-icon
          v-if="statement"
          :payee="statement.payee"
          :size="16"
          class="self-center"
        />

        <div class="mt-[15px] grow overflow-y-auto px-5 sm:px-10">
          <div
            class="flex justify-center text-center text-2xl font-bold text-black"
          >
            <span
              v-if="loading"
              class="w-1/2 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </span>
            <template v-else>
              {{ subtitle }}
            </template>
          </div>

          <div
            v-if="statement && !statement.active"
            class="mt-[30px] text-center text-base font-bold text-eonx-neutral-600"
          >
            <div
              v-if="loading"
              class="mx-auto w-1/2 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>Transaction No. #{{ id }}</template>
          </div>

          <div
            class="mt-[5px] text-center text-base font-bold text-eonx-neutral-600"
          >
            <div
              v-if="loading"
              class="mx-auto w-1/2 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>
              Customer No. {{ statement.clientNumber || '-' }}
            </template>
          </div>

          <div
            class="mt-[5px] text-center text-base font-bold text-eonx-neutral-600"
          >
            <div
              v-if="loading"
              class="mx-auto w-1/2 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>Due date: {{ date }}</template>
          </div>
          <div v-if="statement && statement.isCompleted">
            <div
              class="mt-[5px] text-center text-base font-bold text-eonx-neutral-600"
            >
              Payment date: {{ paidAt }}
            </div>
            <div
              class="mt-[5px] mb-[30px] text-center font-bold text-eonx-neutral-600"
            >
              Status:
              <span class="capitalize" :class="[statusColor]">
                <template v-if="statement.isCompleted">Complete</template>
                <template v-else-if="statement.isPending">Processing</template>
                <template v-else-if="statement.isIncomplete">
                  Incomplete
                </template>
                <template v-else>Failed</template>
              </span>
            </div>
          </div>
          <div class="h-6" />

          <div class="flex items-center justify-between">
            <template v-if="loading">
              <div
                class="w-1/2 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
            </template>
            <template v-else>
              <div class="font-bold text-eonx-neutral-800">
                Total Statement amount
              </div>
              <div class="font-bold text-eonx-neutral-800">
                {{ formatDollar(statement.statementTotal) }}
              </div>
            </template>
          </div>

          <div class="mt-1.5 flex items-center justify-between">
            <template v-if="loading">
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
            </template>
            <template v-else>
              <div class="text-eonx-neutral-600">Discount</div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(statement.discount) }}
              </div>
            </template>
          </div>

          <div
            v-if="!loading && paymentMethods.length > 0"
            class="mt-5 mb-5 border-b border-zinc-100 pb-5"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">Paid with</div>

            <div class="space-y-[15px]">
              <payment-successful-pay-with
                v-for="method in paymentMethods"
                :key="method.id"
                :item="method"
              />
            </div>
          </div>

          <hr v-else class="my-5 h-px bg-eonx-neutral-50" />

          <div class="flex items-center justify-between">
            <template v-if="loading || loadingFees">
              <div
                class="w-1/2 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
            </template>
            <template v-else>
              <div class="font-bold text-eonx-neutral-800">
                Net Statement amount
              </div>
              <div class="font-bold text-eonx-neutral-800">
                {{
                  formatDollar(statement.statementTotal - statement.discount)
                }}
              </div>
            </template>
          </div>
          programFees {{ programFees }}
          <div
            v-if="!user.isCombineFees && programFees"
            class="mt-1.5 flex items-center justify-between"
          >
            <template v-if="loading || loadingFees">
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
            </template>
            <template v-else>
              <div class="text-eonx-neutral-600" data-testid="program-fee">
                Program fee (inc. {{ taxationLabel }})
              </div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(programFees) }}
              </div>
            </template>
          </div>

          <div
            v-if="
              statement && (statement.transactionFees || statement.combinedFees)
            "
            class="mt-1.5 flex items-center justify-between"
          >
            <template v-if="loading || loadingFees">
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-6 text-transparent"
              >
                loading
              </div>
            </template>
            <template v-else>
              <div class="text-eonx-neutral-600" data-testid="precessing-fee">
                Payment Processing Fee (inc. {{ taxationLabel }})
              </div>
              <div class="text-eonx-neutral-600">
                {{
                  formatDollar(
                    user.isCombineFees
                      ? statement.combinedFees
                      : statement.transactionFees
                  )
                }}
              </div>
            </template>
          </div>

          <hr class="my-5 h-px bg-eonx-neutral-50" />

          <div class="mt-[5px] flex items-center justify-between">
            <template v-if="loading || loadingFees">
              <div
                class="w-1/2 animate-pulse rounded bg-eonx-neutral-50 leading-9 text-transparent"
              >
                loading
              </div>
              <div
                class="w-1/3 animate-pulse rounded bg-eonx-neutral-50 leading-9 text-transparent"
              >
                loading
              </div>
            </template>
            <template v-else>
              <div
                class="text-2xl font-bold text-eonx-neutral-800"
                data-testid="total-fee"
              >
                <template v-if="statement.isCompleted">
                  Total Amount (inc. {{ taxationLabel }})
                </template>
                <template v-else>Total Amount Due</template>
              </div>
              <div class="text-2xl font-bold text-eonx-neutral-800">
                <span v-if="statement.isNew">
                  {{ formatDollar(subTotalWithProgramFees) }}
                </span>
                <span v-else>
                  {{ formatDollar(total) }}
                </span>
              </div>
            </template>
          </div>

          <points-panel
            v-if="isPaymentOrderPoints"
            :total="pointsEarned"
            :loading="loading"
            class="-mx-5 mt-5"
          />
        </div>

        <div class="px-5 sm:px-10">
          <div
            class="left-0 flex w-full justify-center space-x-2.5 bg-white py-5 sm:sticky sm:bottom-0 sm:mb-0"
          >
            <div v-if="canPay">
              <base-button @click="pay">Pay now</base-button>
            </div>
          </div>
        </div>
      </div>
      <tax-invoice
        v-if="!loading"
        is-type-statement-order
        :content="printContent"
      />
    </div>
  </div>
</template>
